import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Fitting } from './features/fitting/Fitting';
import { useSearchParams } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { env } from './config';

function App() {

  let showEnv = null;
  if (env !== 'PROD') {
    showEnv = (
      <b style={{ color: '#7bd8b4' }}>[{env}]&nbsp;</b>
    )
  }

  return (
    <div>
      <Navbar style={{ backgroundColor: '#731e3e' }} variant="dark" >
        <Container>
          <Navbar.Brand><img src="logo36.png" />&nbsp;&nbsp;&nbsp;{showEnv}<b>固巧快 消防衣線上套量</b></Navbar.Brand>
        </Container>
      </Navbar>
      <Fitting />
    </div>
  );
}

export default App;
