import axios from 'axios'

import { apiHost } from '../config';

const APP_QUERY_KEY = "20c4e343-0b8d-4c01-934c-1b8d51974d43";

const createParam = (method, url, header, body) => {
    let _headers = header;

    if (_headers === undefined) {
        _headers = {
            'Accept': 'application/json;charset=UTF-8',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }

    _headers['Authorization'] = 'Basic ' + APP_QUERY_KEY;

    let _param = {};
    let _method = method.toUpperCase();
    if (_method === 'GET' || _method === 'HEAD') {
        _param = {
            method: _method,
            url: apiHost + url,
            headers: _headers,
            withCredentials: false
        };
    } else {
        _param = {
            method: _method,
            url: apiHost + url,
            headers: _headers,
            data: body,
            withCredentials: false
        };
    }
    return _param;
}

export async function authAxios(method, url, body) {
    return new Promise((resolve, reject) => {
        return axios(createParam(method, url, undefined, body))
            .then(function (response) {
                resolve(response.data);
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                // TODO:
                return {};
            });
    });
}