import { authAxios } from '../../utils/AuthAxios';

export async function checkOrderCanFitting(arg) {
    let url = '/firefighter/fittingapp/' + arg.orderId + '/checkCanFitting/' + arg.checkId;
    return authAxios('GET', url);
}

export async function searchFittingListByName(arg) {
    let url = '/firefighter/fittingapp/' + arg.orderId + '/searchFittingAppListByName?searchName=' + arg.searchName;
    return authAxios('GET', url);
}

export async function findFittingRecord(arg) {
    let url = '/firefighter/fittingapp/' + arg.orderId + '/suits/' + arg.suitId;
    return authAxios('GET', url);
}

export async function saveFittingRecord(arg) {
    let url = '/firefighter/fittingapp/' + arg.orderId + '/suits/' + arg.suitId;
    let body = { suitRecord: arg.suitRecord, fittingRecord: arg.fittingRecord, signature: arg.signature }
    return authAxios('PUT', url, body);
}