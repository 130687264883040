import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    checkOrderCanFitting,
    searchFittingListByName,
    findFittingRecord,
    saveFittingRecord,
} from './fittingAPI';

export const fetchCheckOrderCanFitting = createAsyncThunk(
    'fitting/checkOrderCanFitting',
    async (arg, thunkAPI) => {
        console.log('execute checkOrderCanFitting');
        return await checkOrderCanFitting(arg);
    }
)

export const fetchSearchFittingListByName = createAsyncThunk(
    'fitting/searchFittingListByName',
    async (arg, thunkAPI) => {
        console.log('execute searchFittingListByName');
        return await searchFittingListByName(arg);
    }
)

export const fetchFindFittingRecord = createAsyncThunk(
    'fitting/findFittingRecord',
    async (arg, thunkAPI) => {
        console.log('execute findFittingRecord');
        return await findFittingRecord(arg);
    }
)

export const fetchSaveFittingRecord = createAsyncThunk(
    'fitting/saveFittingRecord',
    async (arg, thunkAPI) => {
        console.log('execute saveFittingRecord');
        return await saveFittingRecord(arg);
    }
)

export const fittingSlice = createSlice({
    name: 'fitting',
    initialState: {
        loading: false,
        orderCanFitting: null,
        searchName: '',
        searchAlertMsg: null,
        searchList: [],
        selectedSuitId: 0,
        selectedSuit: { suitId: 0 },
        fittingRecord: [],
        canEdit: true,
        showSaveFittingRecord: false,
        toastAlertStyle: '',
        toastAlertMessage: '',
        incompleteData: ''
    },
    reducers: {
        changeSearchName: (state, action) => {
            state.searchName = action.payload;
        },
        changeSearchAlertMsg: (state, action) => {
            state.searchAlertMsg = action.payload;
        },
        changeSelectedSuitId: (state, action) => {
            state.selectedSuitId = action.payload;
        },
        changeSuitRecord: (state, action) => {
            state.selectedSuit[action.payload.type] = action.payload.value
        },
        changeFittingRecord: (state, action) => {
            let row = action.payload.row;
            let type = action.payload.type;
            let value = action.payload.value;
            let rowData = state.fittingRecord[row];
            rowData[type] = value;
            state.fittingRecord[row] = rowData;
        },
        changeCanEdit: (state, action) => {
            state.canEdit = action.payload;
        },
        changeShowSaveFittingRecord: (state, action) => {
            state.showSaveFittingRecord = action.payload;
        },
        clearAlert: (state, action) => {
            state.toastAlertStyle = '';
            state.toastAlertMessage = '';
        },
        showAlert: (state, action) => {
            state.showSaveFittingRecord = true;
            state.toastAlertStyle = action.payload.toastAlertStyle;
            state.toastAlertMessage = action.payload.toastAlertMessage;
        },
        changeIncompleteData: (state, action) => {
            state.incompleteData = action.payload;
        },
    },
    extraReducers: {
        [fetchCheckOrderCanFitting.pending]: (state, action) => {
            // console.log('loading');
            state.loading = true;
        },
        [fetchCheckOrderCanFitting.fulfilled]: (state, action) => {
            // console.log(action);
            state.loading = false;
            state.orderCanFitting = action.payload
            console.log('end checkOrderCanFitting.fulfilled');
        },
        [fetchSearchFittingListByName.fulfilled]: (state, action) => {
            // console.log(action);
            state.searchList = action.payload.data;
            if (!action.payload.data || action.payload.data.length == 0) {
                state.searchAlertMsg = '查無資料';
            }
            console.log('end fetchSearchFittingListByName.fulfilled');
        },
        [fetchFindFittingRecord.fulfilled]: (state, action) => {
            // console.log(action.payload.data);
            state.fittingRecord = action.payload.data.fittingList;
            state.selectedSuit = action.payload.data.fittingMap;
            state.canEdit = (action.payload.data.fittingMap.fitted_cnt === 0);
            console.log('findFittingRecord.fulfilled');
        },
        [fetchSaveFittingRecord.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.showSaveFittingRecord = true;
            state.toastAlertStyle = action.payload.alertStyle;
            state.toastAlertMessage = action.payload.displayMessage;
            if (action.payload.alertStyle === 'success') {
                state.fittingRecord = action.payload.data.fittingList;
                state.selectedSuit = action.payload.data.fittingMap;
            }
            console.log('saveFittingRecord.fulfilled');
        },
    },
});

export const {
    changeSearchName,
    changeSearchAlertMsg,
    changeSelectedSuitId,
    changeSuitRecord,
    changeFittingRecord,
    changeCanEdit,
    changeShowSaveFittingRecord,
    clearAlert,
    showAlert,
    changeIncompleteData
} = fittingSlice.actions;

export default fittingSlice.reducer;